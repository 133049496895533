/* You can add global styles to this file, and also import other style files */
@import '~animate.css/animate.min';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); 

@import '~ngx-sharebuttons/themes/modern/modern-light-theme';

body,p,h1,h2,h3,h4,h5,h6,span,small , i ,td,tr,table, u, a , li, b , button , input , label{
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
  }
  
  ::-webkit-input-placeholder { /* Edge */
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
    font-size: 13px;
    padding-left: 5px;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
    padding-left: 5px;
  }
  
  ::placeholder {
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
    padding-left: 7px;
  }
  
  button , .btn{
    font-weight: 350 !important;
    font-size: 13px;
    font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif !important;
  }
  
  .graph-wrapper {
    margin: 50px 0;
  }

  .white-line {
    border-top: 1px solid #E8EBEE;
  }
  
  .close:focus {
    outline: none;
  }

  .modal-demo-title {
    color: #4CC3B6;
    font-family: 'Poppins';
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.5px;
  } 
  
  .justify-content-evenly {
    justify-content: space-evenly;
  }

  .data-unavailable {
    font-size: 30px;
    line-height: 42px;
    color: #F07F23;
    margin: 18% auto;
    text-align: center;
  }

  .scrollX-hidden {
    overflow-x: hidden;
  }
  
  body {
    overflow-x: hidden;
  }

  .animate__delay {
    &-500 {
      --animate-duration: 500ms;
    }
    &-1000 {
      --animate-duration: 1000ms;
    }
    &-1200 {
      --animate-duration: 1200ms;
    }
    &-1400 {
      --animate-duration: 1400ms;
    }
    &-1600 {
      --animate-duration: 1600ms;
    }
    &-1800 {
      --animate-duration: 1800ms;
    }
  } 
  
.sb-modern-light-theme .sb-button {
  margin: 0px;
  .sb-wrapper {
    min-width: 0 !important;
    .sb-content .sb-icon svg {
      color: #ffffff; 
    } 
  }   
  button:focus {
    outline: none; 
  }
}  

.share-btn1 {
  svg {
    color: #37596D !important;
  }
}